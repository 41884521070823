@import 'src/styles/helpers';

.clutchLink {
    position: absolute;
    top: -60px;
    left: 68%;
    padding: 12px 16px;
    border-radius: 200px;
    @include setProperty('background', var(--secondary-800), var(--secondary-50));
    display: flex;
    flex-direction: column;
    gap: 8px;
    transform: rotate(19deg);

    @include media(mobile) {
        display: none;
    }

    @include media(desktopAll) {
        top: -60px;
        left: 75%;
    }

    .titleWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .title {
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            line-height: 14px;
            letter-spacing: 0.56px;
            text-transform: capitalize;
        }
    }
  
    .stars {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .star {
        width: 12px;
        height: 12px;
        color: var(--primary-900);
      }
    }
  }