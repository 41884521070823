@import 'src/styles/helpers';

.casesSliderBlock {
    margin-bottom: 64px;

    @include media(notMobile) {
        margin-bottom: 120px;
    }

    @include media(portrait) {
        margin-bottom: 104px;
    }
}

.caseImage {
    max-height: 180px;
    max-width: 300px;

    @include media(notMobile) {
        max-height: 360px;
        max-width: 608px;
    }

    @include media(desktopBig) {
        max-width: 860px;
        max-height: 496px;
    }

    @include media(desktopHuge) {
        max-width: 860px;
        max-height: 496px;
    }
}

.caseSlide {
    position: relative;

    .caseDescription {
        position: absolute;
        bottom: -100%;
        left: 0;
        width: 100%;
        padding: 48px 24px 16px;
        transition: all ease-in-out 0.3s;
        @include setProperty(background, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.73) 55.32%, rgba(0, 0, 0, 0.94) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.73) 55.32%, rgba(255, 255, 255, 0.94) 100%));
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        font-family: 'KharkivTone', sans-serif;

        .description {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;

            @include media(notMobile) {
                font-size: 28px;    
                line-height: 36px;
            }
        }

        .title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.183px;
            font-weight: 300;
            opacity: 0.6;

            @include media(notMobile) {
               font-size: 16px;
                line-height: 32px; 
            } 
        }
    }


    &:hover {
        .caseDescription {
            bottom: 0;
        }
    }
}

.slider {
    .sliderTrack {
        clip-path: polygon(
            calc((100vw - 300px - 28px) / 2) 0%,
            calc(100vw - ((100vw - 300px - 28px) / 2)) 0%,
            100% 5%, 100% 95%,
            calc(100vw - ((100vw - 300px - 28px) / 2)) 100%, 
            calc((100vw - 300px - 28px) / 2) 100%,
            0 95%, 0 5%);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 10;

            @include media(notMobile) {
                width: calc((100vw - 608px - 52px) / 2);
            }
        }

        &::before {
            left: 0;
            @include setProperty(background, linear-gradient(90deg, rgba(0,0,0,0.95) 0%, rgba(255,255,255,0) 100%), linear-gradient(90deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0) 100%));
        }
        &::after {
            right: 0;
            @include setProperty(background, linear-gradient(270deg, rgba(0,0,0,0.95) 0%, rgba(255,255,255,0) 100%), linear-gradient(270deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0) 100%));
        }

        @include media(notMobile) {
            clip-path: polygon(
                calc((100vw - 608px - 52px) / 2) 0%,
                calc(100vw - ((100vw - 608px - 52px) / 2)) 0%,
                100% 5%, 100% 95%,
                calc(100vw - ((100vw - 608px - 52px) / 2)) 100%,
                calc((100vw - 608px - 52px) / 2) 100%,
                 0 95%, 0 5%);
        }

        @include media(desktopBig) {
            clip-path: polygon(
                calc((100vw - 860px - 52px) / 2) 0%,
                calc(100vw - ((100vw - 860px - 52px) / 2)) 0%,
                100% 5%, 100% 95%,
                calc(100vw - ((100vw - 860px - 52px) / 2)) 100%,
                calc((100vw - 860px - 52px) / 2) 100%,
                 0 95%, 0 5%);
        }

        @include media(desktopHuge) {
            clip-path: polygon(
                calc((100vw - 860px - 52px) / 2) 0%,
                calc(100vw - ((100vw - 860px - 52px) / 2)) 0%,
                100% 5%, 100% 95%,
                calc(100vw - ((100vw - 860px - 52px) / 2)) 100%,
                calc((100vw - 860px - 52px) / 2) 100%,
                 0 95%, 0 5%);
        }
    }
}

.activeSlide {
    border: 2px solid black;
}