@import "src/styles/helpers";

.about {
  @include pageHeaderPadding;

  &__stats {
    margin-top: 80px;
    display: grid;
    grid-auto-rows: 1fr;
    gap: var(--margin-xs);

    @include media(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.hero {
  padding-top: 140px;
  padding-bottom: var(--margin-xxl);
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  @include media(tablet) {
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-bottom: 120px;
  }

  &__ellipse {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    width: 848px;
    height: 49px;
    filter: blur(71.5px);
    opacity: 0.4;
    left: -400px;
    top: 68px;
    transform: rotate(-30.837deg);
    transform-origin: right top;

    &_desktop {
      display: none;

      @include media(desktopAll) {
        display: block;
        right: -600px;
        left: auto;
        top: 200px;
      }
    }
  }

  &__content {

    @include media(tablet) {
      display: grid;
      gap: 80px;
      grid-template-columns: 261px 1fr;
    }

    @include media(desktopAll) {
      display: grid;
      gap: 80px;
      grid-template-columns: 442px 1fr;
    }

    @include media(desktopBig) {
      grid-template-columns: 442px 465px;
      justify-content: space-between;
    }

    @include media(desktopHuge) {
      grid-template-columns: 442px 465px;
      justify-content: space-between;
    }
  }
}

.title {
  text-transform: uppercase;
  margin-bottom: 62px;
  max-width: 261px;
  white-space: pre-wrap;

  @include media(tablet) {
    margin-bottom: 0;
    max-width: none;
  }

  @include media(desktopAll) {
    margin-bottom: 0;
    max-width: none;
  }
}

.tile {
  border: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  padding: 31px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 18px 1fr;
  justify-content: space-between;
  gap: 16px;

  @include media(tablet) {
    display: flex;
    flex-direction: column;
    height: 242px;
    padding: var(--margin-lg);

    &:nth-of-type(4) {
      grid-column: 1 / 2;
    }
  }

  @include media(desktopAll) {
    display: flex;
    flex-direction: column;
    height: 242px;

    &:nth-of-type(2) {
      grid-column: 3 / 4;
    }

    &:nth-of-type(5) {
      grid-column: 4 / 5;
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;

    @include media(portrait) {
      font-size: 32px;
      line-height: 40px;
    }

    @include media(landscape) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__value {
    font-size: 14px;
    line-height: 2;
    letter-spacing: var(--ls-183);
    font-weight: 300;
  }
}

.icon {
  margin-bottom: 30px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include setProperty(color, var(--primary-500), var(--primary-900));

  svg {
    height: 100%;
    width: 100%;
  }
}

.startProjectButton {
  @include setProperty(border-color, var(--primary-500), var(--primary-900));
  @include setProperty(color, var(--primary-500), var(--primary-900));

  @include media(mobile) {
    align-items: center;
    grid-template-columns: 32px 1fr;
  }

  @include media(tablet) {
    grid-column: 2 / 3;
  }

  .icon {

    @include media(mobile) {
      margin-bottom: 0;
    }
  }

  @include media(mobile) {
    order: 1;
  }

  @include media(desktopAll) {
    grid-column: 4 / 5;
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;

    @include media(landscape) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &:hover {
    @include setProperty(border-color, var(--primary-300), var(--primary-700));
    @include setProperty(color, var(--primary-300), var(--primary-700));
  }
}

.videoWrap {
  overflow: hidden;
  height: 0;
  position: relative;
  margin-top: var(--margin-xxl);
  padding-bottom: 45.69%;
  /* proportions from mockup */

  @include media(tablet) {
    margin-top: 120px;
  }

  @include media(desktopAll) {
    margin-top: 120px;
  }
}

.video {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-bottom: 76.25%;
  /* 16:9 */
  height: 0;
  width: 100%;

  @include media(desktopAll) {
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__controlButton {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    color: var(--light-bg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 64px;
      height: 64px;

      @include media(desktopAll) {
        width: 92px;
        height: 92px;
      }
    }

    &:hover,
    &:focus {
      outline: none;
      color: var(--primary-900);
    }
  }
}

.videoModal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  display: flex;
  align-items: center;
  justify-content: center;

  &__playerWrap {
    width: calc(100% - 48px);
    max-width: 500px;
    margin-left: 24px;
    margin-right: 24px;

    @include media(tablet) {
      max-width: none;
      width: calc(100% - 160px);
    }

    @include media(desktopAll) {
      max-width: none;
      width: calc(100% - 400px);
    }
  }

  &__player {
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    width: 100%;
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.closeButton {
  position: fixed;
  z-index: 3;
  right: var(--margin-md);
  top: var(--margin-md);
  width: 40px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-200));

  @include media(desktopAll) {
    right: 52px;
    top: 32px;
  }

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}